import { FIELDS, ROLE_FORM } from '../../../../../../constants/roles'
import { FormsFieldPreset } from '@wix/forms-common'
import { GeneralText } from './general-text'
import { GFPP_IDS, GFPP_ACTIONS, GFPP } from '../../../../manifests/manifest-commons'

// in use for payment form custom item (adding this field with the items the user given)
export class GeneralCustomAmount extends GeneralText {
  public get role() {
    return FIELDS.ROLE_FIELD_CUSTOM_AMOUNT
  }

  public get fieldType() {
    return FormsFieldPreset.GENERAL_CUSTOM_AMOUNT
  }

  public get props() {
    return {
      required: true,
    } as any
  }

  public get manifest() {
    return {
      relatedToRole: ROLE_FORM,
      displayName: this.translate('manifest.paymentField.displayName'),
      behavior: {
        essential: {
          enabled: true,
          text: this.translate('manifest.paymentField.restoreText'),
        },
        duplicatable: false,
      },
      gfpp: {
        desktop: {
          helpId: '46c6c905-bf04-4de7-86b6-dd0d152be96a',
          mainAction1: {
            actionId: GFPP_IDS.NEW_FORM_SETTINGS,
            label: this.translate('manifest.paymentField.gfppTitle')
          },
          mainAction2: {
            actionId: GFPP_IDS.DYNAMIC_FIELD_SETTINGS,
            label: this.translate('fieldSettings.gfppTitle'),
          },
          iconButtons: {
            [GFPP_ACTIONS.SETTINGS]: GFPP.REMOVE,
          },
        },
        mobile: {
          helpId: '46c6c905-bf04-4de7-86b6-dd0d152be96a'
        }
      },
    }
  }
}
